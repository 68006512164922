// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '100'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'service_name',
    Object: 'value',
    label: '',
    width: '180'
  }, {
    prop: 'log',
    label: '',
    img: true,
    width: '180'
  }, {
    prop: 'appid',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'client_type',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'site',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'email',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'telephone',
    Object: 'value',
    label: '',
    width: '220'
  }, {
    prop: 'lat',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'lon',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'modules_array',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'updated_at',
    Object: 'value',
    label: '',
    width: '150'
  }]
}
